import React, { useState } from "react"

import LazyAnimationImage from '../components/lazeeAnimationImage'

import Layout from "../components/layout"
import "../styles/styles.css"

import { graphql, navigate, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
// import { BgImage } from "gbimage-bridge"

import { Container, Row, Col, Card, Button, Alert, Form } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import { StaticImage } from "gatsby-plugin-image"

import Head from "../components/head"

/////////////////////////////////////////////////////////

// Below is an answer from chatGPT on how to block a form user from submitting the form when they don't purchase the minimum amount of $150.

// To prevent a form user from submitting their form if they haven't purchased a minimum amount of product, you can implement validation on the client-side using JavaScript. Here's an example of how you could achieve this:

// First, you need to add an event listener to the form's submit event. This will allow you to intercept the form submission and run your custom validation function.

// const form = document.querySelector('form');
// form.addEventListener('submit', validateForm);


// In your validation function, you can access the form data and check if the user has purchased the minimum amount of product. You can do this by accessing the form's input fields and checking their values. For example, if you have a "quantity" field, you can check its value and compare it to the minimum amount required:


// function validateForm(event) {
//   event.preventDefault(); // Prevent the form from submitting

//   const quantity = parseInt(document.querySelector('#quantity').value);
//   const minQuantity = 10; // Set your minimum required quantity here

//   if (quantity < minQuantity) {
//     alert(`You must purchase at least ${minQuantity} products to submit the form.`);
//     return; // Stop the form submission
//   }

//   // If the validation passed, submit the form
//   form.submit();
// }

// Finally, you can display an error message if the validation fails and prevent the form submission by calling event.preventDefault(). If the validation passes, you can submit the form using form.submit().

// Note that this client-side validation is not secure and can be bypassed by an attacker. For more robust validation, you should also perform server-side validation when the form is submitted.

//////////////////////////////////////////////////////////

export function OneKiloBoxes() {
  return (
    <StaticImage
      // src="../images/Christmas-Vicenzas-2023-B-Crop.jpg"
      src="../images/OneKiloBoxes.jpg"
      alt="Picture of one kilo boxes of Crostoli"
      placeholder="blurred"
      layout="constrained"
      width={1000}
      aspectRatio={2.22}
    />
  )
}

export function HalfKiloBoxes() {
  return (
    <StaticImage
      // src="../images/Christmas-Vicenzas-2023-B-Crop.jpg"
      src="../images/HalfKiloBoxes.jpg"
      alt="Picture of half kilo boxes of Crostoli"
      // placeholder="blurred"
      layout="constrained"
      width={1000}
      aspectRatio={2.22}
    />
  )
}

export function TreeCrostoli() {
  return (
    <StaticImage
      // src="../images/Christmas-Vicenzas-2023-B-Crop.jpg"
      src="../images/Crostoli-Trees-Vanilla.jpg"
      alt="Picture of Vanilla Crostoli Trees"
      // placeholder="blurred"
      layout="constrained"
      objectPosition="bottom"
      width={1000}
      aspectRatio={2.22}
    />
  )
}


const OrderPage = ({ data }) => {

  

  // const { whiteWallBackground } = useStaticQuery(
  //   graphql`
  //     query { 
  //       whiteWallBackground: file(relativePath: {eq: "Easter2021-Background-WhiteWall.jpg"}) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 2000, 
  //             quality: 50, 
  //             webpOptions: {quality: 70}
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const sources = getImage(whiteWallBackground)
  
    
   

  const [contact, setContact] = useState({
    kiloQuantity: "0",
    halfKiloQuantity: "0",
    treeCrostoliQuantity: "0",
    // totalAmount: "0",
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    address: "",
    address1: "",
    city: "",
    province: "",
    postalCode: "",
    deliveryWeek: "",
    deliveryDay: "",
    deliveryTime: "",
  })


  // The handleChange function below is for handling changes to the forms' input fields

  function handleChange(e) {
    const { name, value } = e.target

    setContact(prevValue => {
      return {
        ...prevValue,
        [name]: value,
      }
    })

  } 

  // The encode and handleSubmit functions below are for submitting form data to Netlify

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    e.preventDefault();  // Prevent the default form submission behavior immediately
    console.log(contact); // Log the state to see what is being sent
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...contact }), 
    })
      .then(() => navigate("/confirmation")) // Navigate to confirmation page on success
      .catch(error => alert("Submission error: " + error)); // Show an error alert if there's a problem
  };
  

  // const handleSubmit = e => {
    
  //   fetch("/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: encode({ "form-name": "contact", ...contact }), 
  //   })
  //     .then(() => navigate("/confirmation"))
  //     .catch(error => alert(error)) 
  //   e.preventDefault()  
  // }

  

  // The function below calculates the total cost of the 1kg, 1/2kg and 300g orders

  let kilo = contact.kiloQuantity
  let halfKilo = contact.halfKiloQuantity
  let treeCrostoli = contact.treeCrostoliQuantity

  // treeCrostoliQuantity

  function Total() {
    let num = (kilo * 44.99 + halfKilo * 27.99 + treeCrostoli * 20.99)
    let n = num.toFixed(2)
    return n
  }

  // The if statements below are to determine the delivery day
  // Also to block customers from ordering on certain days

  let date;

  if
  (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Monday") {
    date = "Monday April 14th"
  } else if (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Tuesday") {
    date = "Tuesday April 15th"
  } else if(contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Wednesday") {
    date = "Wednesday April 16th"
  } else if(contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Thursday") {
    date = "Thursday April 17th"
  } else if(contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Friday") {
    date = "unavailable. Please choose a day other than Friday March 31st!"
  } 
  // else if(contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Monday") {
  //   date = "unavailable. Please choose a day other than Monday April 3rd!"
  // } else if(contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Tuesday") {
  //   date = "unavailable. Please choose a day other than Tuesday April 4th!"
  // } else if(contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Wednesday") {
  //   date = "unavailable. Please choose a day other than Wednesday April 5th!"
  // } else if(contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Thursday") {
  //   date = "unavailable. Please choose a day other than Thursday April 6th!"
  // } else if(contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Friday") {
  //   date = "unavailable. Please choose a day other than Friday April 7th!"
  // } 


  // The if statement below is to determine the delivery time AND to not display a delivery time for unavailable delivery dates.

  // Commenting out below will DISPLAY time of day.

  // Uncommenting below will BLOCK time of day.

  let time;

  if (
    // (contact.deliveryWeek === "firstWeek" && contact.
    // deliveryDay === "Monday") ||
    // (contact.deliveryWeek === "firstWeek" && contact.
    // deliveryDay === "Tuesday") ||
    // (contact.deliveryWeek === "firstWeek" && contact.
    // deliveryDay === "Wednesday") ||
    // (contact.deliveryWeek === "firstWeek" && contact.
    // deliveryDay === "Thursday") ||
    (contact.deliveryWeek === "firstWeek" && contact.
    deliveryDay === "Friday") 
    // ||
    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Monday") ||
    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Tuesday") ||
    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Wednesday") ||
    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Thursday") ||
    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Friday") 
    ) 
    {
    time = ""
      } else {
        if (contact.deliveryTime === "Morning"){
          time = "in the morning!"
        } else if (contact.deliveryTime === "Afternoon"){
          time = "in the afternoon!"
        }
  }

  

  return (

    // <BgImage
    //   image={sources}
    //   alt="Christmas red background with white snowflakes"
    //   className="backgroundImage"
    // >

    <div 
    className="backGround">
    
    {/* <Layout> */}
    <Layout>
    <Head title="Order Page"/>

    {/* The container below is the 'Alert' advising customers to order early. */}

    <LazyAnimationImage>
    <Container style={{minWidth: `100%`}}>
        <Row className="justify-content-md-center" style={{ marginTop: `3rem`, marginBottom: `0rem`, padding: `0` }}>
          <Col md={8}>
            <Alert className="alertMessage" variant="purple" style={{margin: 0}}>
              <Alert.Heading style={{fontSize: `2rem`}}>Easter 2025</Alert.Heading>
              <p style={{ fontSize: `1.1rem`, paddingLeft: `10%`, paddingRight: `10%` }}>
                We recommend placing your Easter orders as soon as possible. We stop accepting orders once our production schedule fills to capacity.
              </p>
              <hr />
              <p className="mb-0" style={{fontSize: `1.1rem`, paddingLeft: `10%`, paddingRight: `10%` }}>
                Please order early to avoid disappointment!
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
      </LazyAnimationImage>

      {/* Below is the SOLD OUT alert */}

      
      {/* <LazyAnimationImage>
      <Container>
        <Row className="justify-content-md-center" style={{ marginTop: `3rem`, marginBottom: `0rem`, padding: `0` }}>
          <Col md={8}>
            <Alert className="alertMessage" variant="success" style={{margin: 0}}>
              <Alert.Heading style={{fontSize: `4rem`}}>SOLD OUT</Alert.Heading>
              <p>
                We've reached capacity for Easter 2023 and we've stopped accepting orders.
              </p>
              <hr />
              <p className="mb-0">
                We sincerely apologize for the inconvenience.
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
      </LazyAnimationImage> */}
      

      
      <Container style={{minWidth: `90%`}}>
      
        <Form 
          onSubmit={handleSubmit}
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          // netlify
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
          <LazyAnimationImage>
          <Row>
            <Col md={4}>
              <Card border="dark" className="mb-3 spacing">
                <OneKiloBoxes />
                <Card.Body>
                  <Card.Title>
                    <h2>One Kilogram Crostoli Gift Boxes</h2>
                  </Card.Title>
                  <Card.Text>
                    <h3 className="oneBoxSpace">$44.99</h3>
                  </Card.Text>

                  {/* 1kg box quantity */}

                    <Form.Label
                      // className="my-1 mr-2 text-center"
                      // htmlFor="inlineFormCustomSelectPref"
                      htmlFor="One-Kilo-Box"
                    >
                      Currently available - 1kg Vanilla Crostoli Box
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      id="One-Kilo-Box"
                      custom
                      onChange={handleChange}
                      name="kiloQuantity"
                      value={contact.kiloQuantity}
                      style={{width: `100%`, height: `3rem` }}
                    >
                      <option value="0">Choose quantity...</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                      <option value="32">32</option>
                      <option value="33">33</option>
                      <option value="34">34</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                      <option value="50">50</option>
                    </Form.Control>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card border="dark" className="mb-3 spacing">
                <HalfKiloBoxes />
                <Card.Body>
                  <Card.Title>
                    <h2>Half Kilogram Crostoli Gift Boxes</h2>
                  </Card.Title>
                  <Card.Text>
                    <h3 className="halfBoxSpace">$27.99</h3>
                  </Card.Text>

                  {/* 1/2kg box quantity */}

                    <Form.Label
                      // className="my-1 mr-2 text-center"
                      htmlFor="Half-Kilo-Box"
                    >
                      Currently available - 1/2kg Vanilla Crostoli Box
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      id="Half-Kilo-Box"
                      custom
                      onChange={handleChange}
                      name="halfKiloQuantity"
                      value={contact.halfKiloQuantity}
                      style={{width: `100%`, height: `3rem` }}
                    >
                      <option value="0">Choose quantity...</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                      <option value="32">32</option>
                      <option value="33">33</option>
                      <option value="34">34</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                      <option value="50">50</option>
                    </Form.Control>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card border="dark" className="mb-3 spacing">
       
                <TreeCrostoli />
                
                <Card.Body>
                  <Card.Title>
                    <h2>Three hundred grams Crostoli</h2>
                  </Card.Title>
                  <Card.Text>
                    <h3 className="treeSpace">$20.99</h3>
                  </Card.Text>

                  {/* 300g tree quantity */}

                    <Form.Label
                      // className="my-1 mr-2 text-center"
                      htmlFor="300g-Tree"
                    >
                      Currently available - 300g Vanilla Crostoli Tree
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      id="300g-Tree"
                      custom
                      onChange={handleChange}
                      name="treeCrostoliQuantity"
                      value={contact.treeCrostoliQuantity}
                      style={{width: `100%`, height: `3rem` }}
                    >
                      <option value="0">Choose quantity...</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                      <option value="32">32</option>
                      <option value="33">33</option>
                      <option value="34">34</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                      <option value="50">50</option>
                    
                    </Form.Control>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          </LazyAnimationImage>

          {/* Calculating Total amount for customer */}

          <LazyAnimationImage>
            <Card border="dark">
            {/* The styling below will make the "please ensure your order...!" message the colour red when it's displayed */}
              <Card.Body style={Total() > 150 || Total() < 0.01 ? {fontSize: `3rem`} : {fontSize: `3rem`, color: `rgb(225, 4, 6)`}}>
                
                    
                
                        {Total() > 150 || Total() < 0.01
                        ? "Your total is $" + 
                        
                     
                        Total()
                    
                        : "Please ensure your order surpasses the $150 minimum!"}

                    
      
              </Card.Body>
            </Card>
          </LazyAnimationImage>

          {/* Customer Information Title (no form inputs here) */}

          
          <Card border="dark" style={{ width: `100%`, marginTop: `3rem` }}>
          <LazyAnimationImage>
            <Card.Header style={{ fontSize: `2rem` }}>
              Customer Information
                <p className="text-muted" style={{ fontSize: `0.8rem`, marginTop: `0.3rem` }}>
                  We'll never share your information with anyone else.&ensp;Fields marked with an * are required.
                </p>
            </Card.Header>
          </LazyAnimationImage>

          {/* Customer Information form inputs begin here */}

            <Card.Body style={{textAlign: `left`}}>
              <Form.Row> 
                {/* <Row className="mb-3"> */}
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={handleChange}
                      name="firstName"
                      value={contact.firstName}
                    /> 
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={handleChange}
                      name="lastName"
                      value={contact.lastName}
                    /> 
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="formGridTelephone">
                    <Form.Label>Telephone Number *</Form.Label>
                    <Form.Control
                      required
                      // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      type="tel"
                      onChange={handleChange}
                      name="telephone"
                      value={contact.telephone}
                      placeholder="eg. 012-345-6789"
                      // title="Please input a valid phone number"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6"  controlId="formGridEmail">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      onChange={handleChange}
                      name="email"
                      value={contact.email}
                      placeholder="eg. crostoli@example.com"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="formGridAddress1">
                    <Form.Label>Address *</Form.Label>
                    <Form.Control
                      required
                      onChange={handleChange}
                      name="address"
                      value={contact.address}
                    />
                  </Form.Group>
                </Form.Row>
                
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="formGridCity">
                    <Form.Label>City *</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      onChange={handleChange}
                      name="city"
                      value={contact.city}
                    >
                      <option selected disabled value="">Choose...</option>
                      <option value="Toronto">Toronto</option>
                      <option value="Mississauga">Mississauga</option>
                      <option value="Brampton">Brampton</option>
                      <option value="Vaughan">Vaughan</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="formGridProvince">
                    <Form.Label>Province</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="province"
                      value="Ontario"
                      // {contact.province}
                      placeholder="Ontario"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="4"  controlId="formGridPostalCode">
                    <Form.Label>Postal Code *</Form.Label>
                    <Form.Control
                      required
                      onChange={handleChange}
                      name="postalCode"
                      value={contact.postalCode}
                      placeholder="eg. X1Y-2Z3"
                    />
                  </Form.Group>
                </Form.Row>
            </Card.Body>
          </Card>


          {/* Delivery Information */}


          <Card
            border="dark"
            style={{ width: `100%`, marginTop: `3rem`, marginBottom: `3rem` }}
          >
            <Card.Header style={{ fontSize: `2rem` }}>
              Delivery Preferences
            </Card.Header>
            <Card.Body style={{textAlign: `left`}}>
                <Form.Group
                  as={Col}
                  controlId="validationWeekPreference"
                  className="text-left"
                >
                  <Form.Label>Preferred Delivery Week</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    defaultValue="Choose..."
                    onChange={handleChange}
                    name="deliveryWeek"
                    value={contact.deliveryWeek}
                    style={{marginBottom: `1rem`}}
                  >
                    <option selected disabled value="">Choose...</option>
                    <option value="firstWeek">
                      Week before Easter (April 14th - April 17th)
                      {/* Two week before Easter (Mar 18th - Mar 22nd) */}
                    </option>
                    {/* <option value="secondWeek">
                      One week before Easter (Mar 25th - Mar 29th)
                    </option> */}
                    {/* <option value="thirdWeek">
                      3rd Week of December (Dec 12th - Dec 16th)
                    </option>
                    <option value="fourthWeek">
                      4th Week of December (Dec 19th - Dec 23rd)
                    </option> */}
                  </Form.Control> 
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="validationDayPreference"
                  className="text-left"
                >
                  <Form.Label>Preferred Delivery Day</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    defaultValue="Choose..."
                    onChange={handleChange}
                    name="deliveryDay"
                    value={contact.deliveryDay}
                    style={{marginBottom: `1rem`}}
                  >
                    <option selected disabled value="">Choose...</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                  </Form.Control> 
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="validationTimePreference"
                  className="text-left"
                >
                  <Form.Label>Preferred Delivery Time</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    defaultValue="Choose..."
                    onChange={handleChange}
                    name="deliveryTime"
                    value={contact.deliveryTime}
                    style={{marginBottom: `1rem`}}
                  >
                    <option selected disabled value="">Choose...</option>
                    <option value="Morning">Morning (9am - noon)</option>
                    <option value="Afternoon">Afternoon (noon - 3pm)</option>
                  </Form.Control>
                </Form.Group>

                  {/* The logic below is in the style attribute within the h1 below to make only the unavailable dates red. */}

                  {/* To display in BLACK, comment out below */}

                  {/* To display in RED, uncomment below */}

                  
                  <h1 style={(
                    // (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Monday") ||
                    // (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Tuesday") ||
                    // (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Wednesday") ||
                    // (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Thursday") ||
                    (contact.deliveryWeek === "firstWeek" && contact.deliveryDay === "Friday") 
                    // ||
                    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Monday") ||
                    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Tuesday") ||
                    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Wednesday") ||
                    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Thursday") ||
                    // (contact.deliveryWeek === "secondWeek" && contact.deliveryDay === "Friday") 
                    ) 
                    ? 
                    {color: `rgb(225, 4, 6)`, fontSize: `2rem`, marginTop: `1.5rem`, textAlign: `center`, padding: `0 10% 0`} 
                    : 
                    {color: `rgb(0, 0, 0)`, fontSize: `2rem`, marginTop: `1.5rem`, textAlign: `center`, padding: `0 10% 0`}}>

                    Your chosen delivery day is {date} {time}
                
              </h1>
            </Card.Body>
          </Card>


          {/* Below is delviery information notice */}


          <Container
            className="justify-content-md-center"
            style={{ minWidth: `100%`, marginTop: `4rem`, marginBottom: `8rem` }}
          >
            <Alert className="alertMessage" variant="purple" style={{fontSize: `1.3rem`}}>
              <p>
                We will call you within one business day to confirm your order
                and delivery day.
              </p>
              <hr />
              <p className="mb-0">
                We are processing credit card payments by
                phone to ensure contactless delivery.
              </p>
            </Alert>
          </Container>


          {/* Below is submit order button */}

              <Button
                variant="default"
                size="lg"
                className="submit-button"
                block
                type="submit"
                style={{
                  marginTop: `4em`,
                  marginBottom: `10rem`
                  }}
              >
                Submit your order
              </Button>
        </Form>
      </Container>
    </Layout>

    </div>
    
  
  )
}

export default OrderPage


